<template>
  <div>
    <v-tabs
      v-model="tab"
      color="#FF3700"
      background-color="#ECEFF1"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="my-tabs-background-style"
      touchless
    >
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <v-card-text>
          <component :is="item.content" />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
 import OrganizationDigiBoardsList from './OrganizationDigiBoardsList.vue';
 import DbsDigiBoardsList from './DbsDigiBoardsList.vue';

  export default {
    name: 'AdvertisersDigiBoardsPage',

    components: {
     OrganizationDigiBoardsList,
     DbsDigiBoardsList,
    },
    data: () => ({
      tab: null,
      items: [
        { tab: 'Purchased Devices', content: OrganizationDigiBoardsList },
        { tab: 'Available Devices', content: DbsDigiBoardsList },
      ],
    }),
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tabs-background-style {
    background-color: #ECEFF1 !important;
}
</style>
