<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-television
      </v-icon> Device List
    </h2>
    <v-col
      cols="12"
      lg="12"
      class="text-right"
    >
      <v-btn
        color="#FF3700"
        @click="createDeviceStructure()"
      >
        Group Devices
      </v-btn>
    </v-col>
    <div>
      <organization-digi-boards-list v-if="userOrganizationCategoryCheck('host')" />
      <advertisers-digi-boards-page v-if="userOrganizationCategoryCheck('advertiser')" />
    </div>
  </v-container>
</template>

<script>
import OrganizationDigiBoardsList from './OrganizationDigiBoardsList.vue';
import AdvertisersDigiBoardsPage from './AdvertisersDigiBoardsPage.vue';
import Constants from 'src/constants';
 import global from 'src/mixins/global';

export default {
    name: 'DigiBoardsLandingPage',
    components: {
        'organization-digi-boards-list': OrganizationDigiBoardsList,
        'advertisers-digi-boards-page': AdvertisersDigiBoardsPage,
    },
    mixins: [global],
    data () {
      return {
        showAdvertisersDigiBoardsPage: true,
        showHostDigiBoardsPage: false,
      };
    },
    methods: {
        createDeviceStructure () {
        if (this.permissionCheck('read-group') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$router.push({ name: 'Device Structure' });
      },
    },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
