<template>
  <div>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="organizationDigiBoards"
        :search="search"
        class="elevation-1"
        :loading="updateProgress"
        loader-height="3"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr :class="anyTimeSlotUnderGracePeriod(item.time_slots)">
            <td>{{ item.digi_board.external_id }}</td>
            <td>{{ item.digi_board.board_number }}</td>
            <td>
              <font
                v-if="item.digi_board.health_record !== null"
                :color="getHealthColor(item.digi_board.health_record.status)"
                class="font-my-style"
              >
                {{ item.digi_board.health_record.status | healthStatusUpdate }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>{{ item.digi_board.location }}</td>
            <td>
              <font v-if="item.digi_board.activity !== null && item.digi_board.activity.event_triggered_at !== null">
                {{ item.digi_board.activity.event_triggered_at | convertToLocal }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font
                v-if="item.digi_board.activity !== null && item.digi_board.activity.status !== null"
                :color="boardStatusColor(item.digi_board.activity.status)"
                class="font-my-style"
              >
                {{ item.digi_board.activity.status | boardStatusUpdate }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.digi_board.activity !== null && item.digi_board.activity.board_updated_at !== null">
                {{ item.digi_board.activity.board_updated_at | diffHumans }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="digiBoardDetails(item.digi_board.external_id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </div>
</template>
<script>
  import moment from 'moment';
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';

  export default {
    name: 'OrganizationDigiBoardsList',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      boardStatusUpdate (status) {
        if (status === 'published') {
          return 'Published';
        } else if (status === 'completed') {
          return 'Completed*';
        } else { return 'Pending'; }
      },
      healthStatusUpdate (status) {
        if (status === 'completed') {
          return 'Online*';
        }
        return 'Offline';
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Device Id', value: 'external_id' },
          { text: 'Device Number', value: 'board_number' },
          { text: 'Device Health', value: 'status' },
          { text: 'Location', value: 'location' },
          { text: 'Last Published', value: 'event_triggered_at' },
          { text: 'Device Status', value: 'status' },
          { text: 'Last Updated', value: 'board_updated_at' },
          { text: 'More', value: 'more' },
        ],
        latestBoardActivities: {},
        updateProgress: false,
      };
    },
    computed: {
      organizationDigiBoards () {
        const list = this.$store.getters['digiboards/getOrganizationDigiBoardTimeSlots'];
        const sortedList = list.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        return sortedList;
      },
      boardActivities () {
        return this.$store.getters['digiboards/getBoardActivity'];
      },
      boardActivityLastUpdatedAt () {
        return this.$store.getters['digiboards/getActivityLastUpdatedAt'];
      },
    },
    watch: {
      boardActivityLastUpdatedAt (val) {
        this.updateProgress = true;
        setTimeout(() => {
          this.permissionCheck('read-board-list') && this.latestBoardActivity();
          this.permissionCheck('read-board-list') && this.mergedDigiboardList();
        }, 3000);
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('view-organization-purchase-list') && await this.fetchOrganizationDigiBoardTimeSlots();
      this.loading = false;
    },
    methods: {
      async fetchOrganizationDigiBoardTimeSlots () {
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchOrganizationDigiBoardTimeSlots');
        this.loading = false;
      },
      getColor (active) {
        if (active === true) {
          return '#2E7D32';
        };
        return '#C62828';
      },
      boardStatusColor (status) {
        if (status === 'published') {
          return '#E65100';
        } else if (status === 'completed') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      mergedDigiboardList () {
        for (var item of this.organizationDigiBoards) {
          if (item.digi_board.external_id in this.latestBoardActivities) {
            item.digi_board.activity = this.latestBoardActivities[item.digi_board.external_id];
          }
        }
        this.updateProgress = false;
      },
      latestBoardActivity () {
        const boardActivities = {};
        for (const activity in this.boardActivities) {
          boardActivities[activity] = this.boardActivities[activity][0];
        }
        this.latestBoardActivities = boardActivities;
      },
      digiBoardDetails (externalId) {
        if (this.permissionCheck('read-board') === false && this.permissionCheck('read-board-update-records-list') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$router.push(`/digi-board/${externalId}`);
      },
      getHealthColor (healthStatus) {
        if (healthStatus === 'completed') {
          return '#2E7D32';
        };
        return '#C62828';
      },
      anyTimeSlotUnderGracePeriod (timeSlots) {
        for (const timeSlot of timeSlots) {
          if (timeSlot.subscription_status === 'grace_period') {
        return 'grace_period_alert_style';
          }
        }
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }

.font-my-style {
  font-weight: bold;
}
.grace_period_alert_style {
  animation: blink 1s  infinite;
}
@keyframes blink {
 100% {
   background-color: #f9d1d1;
 }
}
</style>
