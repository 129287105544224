export default {
    methods: {
        permissionCheck (permissionName) {
            const storedUserDetails = localStorage.getItem('user');
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails.user.permissions === null || userDetails.user.permissions.length === 0) {
                return false;
            } else {
                if (userDetails.user.permissions.some(e => e.name === permissionName)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        roleCheck (roleName) {
            const storedUserDetails = localStorage.getItem('user');
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails.user.role.name === roleName) {
                return true;
            } else {
                return false;
            }
        },
        userOrganizationCategoryCheck (category) {
            const storedUserDetails = localStorage.getItem('user');
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails.user.organization.category === category) {
                return true;
            } else {
                return false;
            }
        },
    },
};
