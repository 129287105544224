<template>
  <div>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            lg="4"
          >
            <v-select
              v-model="selectedOrganizationId"
              menu-props="auto"
              :items="hostOrganizationsList"
              item-value="external_id"
              item-text="name"
              label="Select Host Location"
              :disabled="!permissionCheck('view-all-hosts')"
              outlined
              dense
              @input="getDigiBoardsUnderOrganization()"
            />
          </v-col>
          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  v-bind="attrs"
                  fab
                  dark
                  color="#37474F"
                  small
                  v-on="on"
                  @click="fetchDigiBoardDetails()"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-monitor-multiple
                  </v-icon>
                </v-btn>
              </template>
              <span>ALL Devices</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="dbsDigiBoards"
        :search="search"
        class="elevation-1"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.external_id }}</td>
            <td>{{ item.board_number }}</td>
            <td>
              <font
                v-if="item.health_record !== null"
                :color="getHealthColor(item.health_record.status)"
                class="font-my-style"
              >
                {{ item.health_record.status | healthStatusUpdate }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <v-chip
                v-if="item.total_duration !== null"
                color="#37474F"
                small
                outlined
                dark
              >
                {{ item.total_duration }} Sec
              </v-chip>
              <v-chip
                v-else
                color="#37474F"
                small
                outlined
                dark
              >
                N/A
              </v-chip>
            </td>
            <td>{{ item.location }}</td>
            <td>
              {{ item.created_at | convertToLocal }}
            </td>
            <td>
              {{ item.updated_at | diffHumans }}
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="digiBoardTimeSlots(item.external_id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </div>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import Constants from 'src/constants';

  export default {
    name: 'DbsDigiBoardsList',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      healthStatusUpdate (status) {
        if (status === 'completed') {
          return 'Online*';
        }
        return 'Offline';
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Device Id', value: 'external_id' },
          { text: 'Device Number', value: 'board_number' },
          { text: 'Device Health', value: 'status' },
          { text: 'Cycle Duration', value: 'total_duration' },
          { text: 'Location', value: 'location' },
          { text: 'Installed On', value: 'created_at' },
          { text: 'Last Updated', value: 'updated_at' },
          { text: 'View Slots', value: 'view_slots' },
        ],
        selectedOrganizationId: '',
      };
    },
    computed: {
      dbsDigiBoards () {
        const list = this.$store.getters['digiboards/getDigiBoards'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
      hostOrganizationsList () {
        return this.$store.getters['userprofile/getHostOrganizationsList'];
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-board-list') && await this.fetchDigiBoardDetails();
      this.permissionCheck('view-all-hosts') && this.fetchHostOrganizationsList();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardDetails () {
        this.loading = true;
        this.selectedOrganizationId = '';
        await this.$store.dispatch('digiboards/fetchDigiBoards');
        this.loading = false;
      },
      async fetchHostOrganizationsList () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchHostOrganizationsList');
        this.loading = false;
      },
      digiBoardTimeSlots (externalId) {
        if (this.permissionCheck('view-digi-board-time-slots') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$router.push(`/digi-board/${externalId}/time-slots`);
      },
      getHealthColor (healthStatus) {
        if (healthStatus === 'completed') {
          return '#2E7D32';
        };
        return '#C62828';
      },
      async getDigiBoardsUnderOrganization () {
         if (this.permissionCheck('view-organization-boards') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchDigiBoardsUnderOrganization', {
          organizationId: this.selectedOrganizationId,
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }

.font-my-style {
  font-weight: bold;
}
</style>
